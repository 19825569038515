import { defineStore } from "pinia";
import config from "@/config/env";

export const useUserStore = defineStore("user", () => {
  const countryCode = ref();
  const loadCountryCode = async () => {
    const response: { data: { country_code?: string } } = await $fetch(
      config.apiUrl + "/info",
    );
    countryCode.value = response?.data?.country_code?.toUpperCase();

    return countryCode.value;
  };

  loadCountryCode();

  return {
    countryCode,
    loadCountryCode,
  };
});
